.video-container {
    position: relative;

    video#video {
        width: 100%;

    }

    video.tree {
        margin-top: 20px;

        height: 600px;
    }
}
